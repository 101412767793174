import React from "react"
import { Layout, Checkout, SEO } from "../components"

const DetallesCompra = () => {
  return (
    <Layout>
      <SEO title="Detalles de envío" />
      <Checkout />
    </Layout>
  )
}

export default DetallesCompra
